// 先定义一个类型，emit作为发布(传递)，on作为订阅(接收)
// name是事件名称，callback是一个回调函数
// 定义一个调用中心,可以接收多个参数
class Bus  {
  constructor(){
    // 初始化list
    this.list = {} 
		// list:{},//收集存放事件,相当于收集订阅信息,调度中心
	}
  // 发布事件,...args解构多个参数
  emit (name,...args){
      // 获取到list里的数据
      let evnentName= this.list[name]
      evnentName.forEach(fn =>{
          fn.apply(this, args)
      })
  }
  // 接收事件
  on (name, callback){
      // 如果是第一次注册，则为空；如果被多次注册，则采用之前注册的名字
      let fn = this.list[name] || []
      fn.push(callback)
      this.list[name] = fn
  }
}
// 导出bus
export default new Bus()